<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Статистика просмотров по главам</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container v-if="!loadPage">
      <cond-stat class="mb-4"></cond-stat>
      <v-row class="align-center">
        <v-col v-if="books.length > 0" cols="12">
          <v-select
            v-model="selectBook"
            :items="books"
            item-text="title"
            item-value="id"
            label="Книга"
            prepend-icon="$vuetify.icons.bookshelf"
            @change="sortStatistics"
          ></v-select>
        </v-col>

        <!-- <v-col cols="12" sm="3">
          <v-btn dark color="primary" @click="sortStatistics">Искать</v-btn>
        </v-col> -->
      </v-row>

      <v-row class="align-center mb-4">
        <div class="d-flex px-4 pb-4">
          <v-btn
            v-for="(item, index) in periods"
            :key="index"
            small
            class="mr-2"
            @click="changePeriod(item.val)"
            :color="period == item.val ? 'primary' : ''"
          >
            {{ item.text }}</v-btn
          >
        </div>
      </v-row>

      <v-row v-if="!statistics || statistics.length == 0" align="center">
        <no-content route-name title="Данных нет"></no-content>
      </v-row>
      <v-data-table
        v-else
        :headers="headersTable"
        :items="statistics"
        :items-per-page="-1"
        class="statistics_chapters"
        item-key="id_table"
      >
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CondStat from "./CondStat.vue";
export default {
  components: {
    CondStat,
  },
  data: () => ({
    selectBook: "first",
    period: "currentMonth",
    periods: [
      { text: "Последние 30 дней", val: "currentMonth" },
      { text: "Предыдущий месяц", val: "lastMonth" },
      { text: "За год", val: "year" },
      { text: "За все время", val: "all" },
    ],
    optionsTable: {
      itemsPerPage: -1,
    },
    datacollection: null,
  }),
  methods: {
    getStatistics() {
      //event.preventDefault();
      this.loading = true;
      let app = this;
      this.$store
        .dispatch("cabinet_statistics/getStatisticsViewsByChapters", {
          book_id: app.selectBook,
          period: app.period,
        })
        .then((response) => {
          if (response.data.book) {
            app.selectBook = +response.data.book.id;
          }
        });
    },
    goPage(nextPage) {
      if (nextPage != this.$route.query.page) {
        this.$router.push({
          name: this.$route.name,
          //   params: { id: this.bookId },
          query: {
            book_id: this.selectBook,
            page: nextPage,
            period: this.period,
          },
        });
      }
    },
    sortStatistics() {
      if (
        this.period != this.$route.query.period ||
        this.selectBook != this.$route.query.book_id
      ) {
        this.$router.push({
          name: this.$route.name,
          query: {
            book_id: this.selectBook,
            period: this.period,
          },
        });
      }
    },
    changePeriod(value) {
      // console.log(value);
      this.period = value;
      this.sortStatistics();
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      statistics: "cabinet_statistics/statistics",
      books: "cabinet_statistics/only_books",
      headersTable: "cabinet_statistics/headersTable",
      condStat: "cabinet_statistics/condStat",
    }),
  },
  mounted() {
    this.selectBook = this.$route.query.book_id
      ? this.$route.query.book_id
      : "first";
    if (this.selectBook != "first") {
      this.selectBook = +this.selectBook;
    }
    this.period = this.$route.query.period
      ? this.$route.query.period
      : "currentMonth";

    this.getStatistics();
  },
};
</script>
